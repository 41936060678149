import Home from "./pages/home/Home";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  
  
} from "react-router-dom";
import List from "./pages/list/List";
import Login from "./pages/login/Login";
import New from "./pages/new/New";
import ImportFile from "./pages/import/Import";
import Single from "./pages/single/Single";
import { productInput, userInput, customerInput, comserviceInput } from "./formSource";
import SingleProduct from "./pages/singleproduct/singleproduct";
import SingleCustomer from "./pages/singlecustomer/singlecustomer";
import SingleComservice from "./pages/singlecomservice/singlecomservice";
import InvociePage from "./pages/invoice/invoice";
import Newinvoice from "./pages/newinvoice/newinvoice";
import InvoiceDetails from "./pages/invoicedetails/invoiceDetails";
import {useSelector} from 'react-redux'

import Autherror from "./pages/autherror/autherror";
import CreateHeader from "./pages/createheader/createheader";
import Com from "./pages/communication/Com";



const ProtectedRoute = ({children}) =>{
  const user = useSelector((state)=> state.user.userInfo);
      if(user.isAdmin !== "true"){
        return <Navigate to="/autherror" />
      }
    return children ;
}




function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Login/>}/>
            
            <Route path="home" element={<ProtectedRoute><Home/></ProtectedRoute>}/>
            
            <Route path="users">
              <Route index element={<List addlink="/users/new" importlink="/users/importfile" />}/>
              <Route path=":userId" element={<Single/>}/>
              <Route path="new" element={<New inputs = {userInput} title = "Add New User" />}/>
              <Route path="importfile" element={<ImportFile title= "Import Users" />}/>
            </Route>
            <Route path="items">
              <Route index element={<List addlink="/items/new" importlink="/items/importfile"/>}/>
              <Route path=":itemId" element={<SingleProduct/>}/>
              <Route path="new" element={<New inputs = {productInput} title = "Add New Item"/>}/>
              <Route path="importfile" element={<ImportFile title= "Import Products" />}/>
            </Route>
            <Route path="customers">
              <Route index element={<List addlink="/customers/new" importlink="/customers/importfile" />}/>
              <Route path=":customerId" element={<SingleCustomer/>}/>
              <Route path="new" element={<New inputs = {customerInput} title = "Add New Customer"/>}/>
              <Route path="importfile" element={<ImportFile title= "Import Customers" />}/>
            </Route>
            <Route path="comservices">
              <Route index element={<List addlink="/comservices/new" importlink="/comservices/importfile"/>}/>
              <Route path=":comserviceId" element={<SingleComservice/>}/>
              <Route path="new" element={<New inputs = {comserviceInput} title = "Add New Comservice"/>}/>
              <Route path="importfile" element={<ImportFile title= "Import Comservices" />}/>
            </Route>
            <Route path="invoices">
              <Route index element={<InvociePage/>}/>
              <Route path=":invoiceId" element={<InvoiceDetails/>}/>
              <Route path="new" element={<Newinvoice title = "Create Invoice"/>}/>
              <Route path="importfile" element={<ImportFile title= "Import Invoices" />}/>
            </Route>
            <Route path="headers">
              <Route index element={<CreateHeader />}/>
              <Route path=":headerId" element={<InvoiceDetails/>}/>
              <Route path="new" element={<Newinvoice title = "Create Invoice"/>}/>
              
            </Route>
            <Route path="communication">
              <Route index element={<Com />}/>
              <Route path=":tempid" element={<InvoiceDetails/>}/>
            </Route>
            <Route path="autherror"  element={<Autherror/>} />
          </Route>
        </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
