
import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux/es/exports'
import { useNavigate } from 'react-router-dom'
import { updateUserStart, updateUserSuccess, updateUserFailure } from '../../redux/reduxslices/userslice';
import axios from 'axios';


import FormInput from "../../components/FormInput/forminput"
import './Login.scss'

const Form = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    

    const [values, setValues] = useState({
      username:"",
      password:"",
    })

    const inputs = [
        {
            id:1,
            name:"username",
            type:"text",
            placeholder:"Username",
            label:"Username",
            required:true,
            errormessage:"Username must be 3-10 characters long and should not contain special character"
        },
        
        
        {
            id:2,
            name:"password",
            type:"password",
            placeholder:"Password",
            label:"Password",
            required:true,
            errormessage:"Min 8 and max 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
        },
       
    ]

    // const user = useSelector((state)=> state.user.userInfo);

    const handleSubmit = (e) =>{
        e.preventDefault();
        
        const userlogin = async()=>{
    
            dispatch(updateUserStart());
            try{
                const res = await axios.post("https://herokumastercms.herokuapp.com/api/v1/auth/login",values);
                dispatch(updateUserSuccess(res.data.details));
                console.log(res.data.details);
                navigate("/home");
                sessionStorage.setItem('token',res.data.access_token);
            }catch(error){
                dispatch(updateUserFailure());
                navigate("/home");
            }
        }
        userlogin();
        
    }

    const onChange = (e) =>{
        setValues({...values, [e.target.name]: e.target.value});
    }

    

  return (
    <div className="form">
        <div className='form__container'>
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
                {
                    inputs.map((input)=>{
                        return (
                            <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange}/>
                        )
                    })
                }
                <button className='form__submit__btn'>Submit</button>
            </form>
        </div>

    </div>    

    
  )
}

export default Form