import {createSlice} from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name:"user",
    initialState:{
        userInfo:{

        },
        pending:false,
        error:false,
    },

    reducers:{
        updateUserStart:(state)=>{
            state.pending = true;
        },
        updateUserSuccess:(state,action)=>{
            state.pending = false;
            state.userInfo = action.payload;
        },
        updateUserFailure:(state)=>{
            state.error = true;
            state.pending = false;
        }
    }
});


export const {updateUserStart, updateUserSuccess, updateUserFailure} = userSlice.actions;
export default userSlice.reducer;