import React,{useState,useEffect} from 'react'
import { Link, useLocation, useNavigate} from "react-router-dom"
import "../Datatable/datatable.scss"
import { DataGrid} from '@mui/x-data-grid';
import axios from 'axios';


const Datatable = ({dataColumns,dataRows}) => {
  

  const [list,setList] = useState([]);
  
  useEffect(()=>{
    setList(dataRows);
  },[dataRows]);

  const location = useLocation();
  const navigation = useNavigate();

  const handleNavigation = (id) =>{
    navigation(`${location.pathname}/${id}`)
  }

  const handleDelete = (id) =>{
    const token = sessionStorage.getItem('token');
    const api = `https://herokumastercms.herokuapp.com/api/v1${location.pathname.slice(0,20)}/${id}`;
    axios.delete(api,{headers: {'authorization':token}}).then((res)=>{
      alert(res.data);
    });
    setList(list.filter((user)=>{
      return user._id!==id
    }));
     
  }

  


  const actionColumn = [{field:"action", headerName:"Action", width:200,renderCell:(params)=>{
    return (
      <div className='cell__action'>
        <button className="view__button" onClick={()=>handleNavigation(params.row._id)}>
          View
        </button>
        <div className='delete__button' onClick={()=>handleDelete(params.row._id)}>
          Delete
        </div>
      </div>
    )
  }}]

  return (
    <div className='datatable'>
      <DataGrid
        rows={list}
        getRowId={(row) => row._id}
        columns={dataColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  )
}

export default Datatable