import React,{useEffect, useState} from 'react'
import {Link, useLocation} from "react-router-dom"
import "../list/List.scss"
import Sidebar from "../../components/Sidebar/Sidebar"
import Navbar from "../../components/Navbar/Navabar"
import Datatable from '../../components/Datatable/Datatable'
import axios from 'axios'
import { comserviceColumns, customerColumns, productColumns, userColumns } from '../../datatablesource'
const List = ({addlink,importlink}) => {

  const [dbdata, setDbdata] = useState([]);

  const history = useLocation();


  let data;
  switch(history.pathname){
    case "/users" :
      data = {columns:userColumns};
      break;
      case "/customers" :
      data = {columns:customerColumns};
      break;
      case "/items" :
      data = {columns:productColumns};
      break;
      case "/comservices" :
      data = {columns:comserviceColumns};
      break;
      default:
        break;
  }

  useEffect(()=>{
      const token = sessionStorage.getItem('token');
      // console.log(sessionStorage);

      const api = `https://herokumastercms.herokuapp.com/api/v1${history.pathname}/`;

      axios.get(api,{headers: {'authorization':token}}).then((res)=>{setDbdata(res.data)}).catch((err)=>{alert(err)});
      

  },[history.pathname])

  return (
    <div className='list'>
      <Sidebar/>
      <div className="list__container">
        <Navbar/>
        <div className="button__container">
          <Link to = {addlink}>
            <button className='add__button'>Add New</button>
          </Link>
          <Link to = {importlink}>
            <button className='add__button'>Import</button>
          </Link>
        </div>
        <Datatable dataColumns={data.columns} dataRows={dbdata}  />
      </div>
    </div>
  )
}

export default List