import React,{useState,useEffect} from 'react'
import Layout from '../../components/Layout/Layout'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import './newinvoice.scss';
import axios from 'axios';
import { KeyboardBackspaceOutlined,DeleteOutline, AddOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'







const Newinvoice = ({title}) => {

  const [invoiceNo, setInvoiceNo] = useState({
    _id:"",
    name:"",
    title:"",
    cinno:"",
    gstno:"",
    panno:"",
    contactno:"",
    email:"",
    address:"",
    bankname:"",
    bankaccount:"",
    ifsccode:"",
    invoiceprefix:"",
    invoiceno:[],
    invoiceid:[]

  });
  const [searchData,setSearchData] = useState([]);
  const [searchInputValue, setSearchInputValue]= useState("");
  const [searchDisplay,setSearchDisplay] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] =useState([]);
  const [customerData,setCustomerData] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [invoiceId, setInvoiceId] = useState("");
  const [issueddate, setIssuedDate] = useState();
  const [duedate, setDueDate] = useState();
  const [notes,setNotes] = useState("");
  const [itemsData,setItemsData] = useState([]);
  const [modalItemName,setModalItemName] =useState("");
  

  const handleSearchInputChange = (e) => {
    e.preventDefault();
    setSearchInputValue(e.target.value);
    setSearchData(customerData.filter((customer)=>customer.name.toLowerCase().includes(e.target.value.toLowerCase())));
    
  }

  const handleDisplay = () => {
    setSearchData(customerData);
    setSearchDisplay(!searchDisplay);
  
  }

  
  const [isTax, setIsTax] = useState(false);
 
  const [isInterState, setIsInterState] = useState(false);
 
  const [totaltaxablevalue, settotaltaxablevalue] = useState(0);
  const [totaldiscount, settotaldiscount] = useState(0);
  const [totaligst, settotaligst] = useState(0);
  const [totalcgst, settotalcgst] = useState(0);
  const [totalsgst, settotalsgst] = useState(0);

  

  const createModalData = () =>{
    settotaligst(0);
    settotalcgst(0);
    settotalsgst(0);
    settotaltaxablevalue(0);
    settotaldiscount(0);
    

    modalData.forEach((row)=>{
      const total = row.price * row.qty ;
      const discount = total*(row.discountrate/100);
      if(isTax && isInterState){
        const igst = (total-discount)*(row.taxrate/100);
        
        settotaligst((prev ) => prev+igst);
      }
      if(isTax && !isInterState){
        const cgst = (total-discount)*(row.taxrate/200);
        const sgst = (total-discount)*(row.taxrate/200);
        settotalcgst((prev)=> prev+cgst);
        settotalsgst((prev)=> prev+sgst)
        
      }
      settotaltaxablevalue((prev)=> prev+total);
      settotaldiscount((prev)=> prev+discount);
    });
  }

  const deleteModalData = () =>{
    settotaligst(0);
    settotalcgst(0);
    settotalsgst(0);
    settotaltaxablevalue(0);
    settotaldiscount(0);
   
    
    modalData.forEach((row)=>{
      
      const total = row.price * row.qty ;
      const discount = total*(row.discountrate/100);
      if(isTax && isInterState){
        const igst = (total-discount)*(row.taxrate/100);
        settotaligst((prev ) => prev-igst);
      }
      if(isTax && !isInterState){
        const cgst = (total-discount)*(row.taxrate/200);
        const sgst = (total-discount)*(row.taxrate/200);
        settotalcgst((prev)=> prev-cgst);
        settotalsgst((prev)=> prev-sgst)
      }
      settotaltaxablevalue((prev)=> prev-total);
      settotaldiscount((prev)=> prev-discount);
    });
  }


  const navigate = useNavigate();
  const handleModalClose =(e) =>{
    e.preventDefault();
    setIsOpen(!isOpen);

  }
  
  const handleDelete = (e,name)=>{
    e.preventDefault();
    const filteredData = modalData.filter(item =>  item.name !==name);
    setModalData(filteredData);
    deleteModalData();
  }
 
 
  const handleModalDataSave = (e) =>{
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    // console.log(data);
    setModalData((prev)=> [...prev,{name:modalItemName,price:Number(data.price),qty:Number(data.qty),taxrate:Number(data.taxrate),discountrate:Number(data.discountrate)}]);
    setIsOpen(false);
    

  }
  const handleHeaderSelect = (e) =>{
    e.preventDefault();
    // console.log(e.target.options[e.target.selectedIndex].value);
    setInvoiceNo(headerData[e.target.selectedIndex]);
    console.log(headerData[e.target.selectedIndex]);
    const selectedValue = e.target.options[e.target.selectedIndex].value ;
    setInvoiceId(selectedValue);
    
  }
 
  const handleNewRow = (e) =>{
    e.preventDefault();
    setIsOpen(true);

  }
  // 
  const createInvoice =(e)=>{
    e.preventDefault();
    const netpayabletotal = (totaltaxablevalue-totaldiscount)+(totaligst+totalcgst+totalsgst);
    const abc = `${invoiceNo?.invoiceprefix}${invoiceNo.invoiceid.length+1}`
    const createapi = "https://herokumastercms.herokuapp.com/api/v1/invoices/" ;
    const token = sessionStorage.getItem("token");
    const data = {
      no:Date.now(),
      invoiceserial:abc,
      headerid:invoiceId,
      issueddate:issueddate,
      duedate:duedate,
      customername:currentCustomer[0]._id,
      items:modalData,
      grandtotal:totaltaxablevalue,
      totaligst:totaligst,
      totalcgst:totalcgst,
      totalsgst:totalsgst,
      discounttotal:totaldiscount,
      nettotal:netpayabletotal,
      notes:notes,

    }
    axios.post(createapi,data,{headers:{"authorization":token}})
    .then((res)=> alert("Invoice created successfully"));
    
    navigate("/invoices");
  }
  
  useEffect(()=>{
    const api = "https://herokumastercms.herokuapp.com/api/v1/customers/" ;
    const token = sessionStorage.getItem("token");
    axios.get(api,{headers:{"authorization":token}})
    .then((res)=> setCustomerData(res.data));
    createModalData();
  },[modalData]);


  useEffect(()=>{
    const api = "https://herokumastercms.herokuapp.com/api/v1/headers/" ;
    const itemApi = "https://herokumastercms.herokuapp.com/api/v1/items/" ;
    const token = sessionStorage.getItem("token");
    axios.get(api,{headers:{"authorization":token}})
    .then((res)=> setHeaderData(res.data));

    axios.get(itemApi,{headers:{"authorization":token}})
    .then(res=>setItemsData(["Select",...res.data]));


    
  },[])
  
  return (
    <>
    <Layout>
        <div className="invoice__container">
       
        {
          isOpen ? (<div className="item__box">
          <form className="modal__form" onSubmit={handleModalDataSave}>
              <div className="modal__title">
                  <h4>Add Item</h4>
                  <button className="modal__close" onClick={handleModalClose} >Close</button>
              </div>
              <div className="modal__input__box">
              <label htmlFor="">Name</label>
                  <select onChange={(e)=>setModalItemName(e.target.options[e.target.selectedIndex].value)} required={true} className="modal__input" >
                    {
                      itemsData.map((item)=>{
                        return (<option value={item.name}>{item.name}</option>)
                      })
                    }
                  </select>
                  
                  {/* <input className="modal__input" type="text" placeholder="Name" name='name' required={true} /> */}
              </div>
              <div className="modal__input__box">
                  <label htmlFor="">Price</label>
                  <input className="modal__input" type="Number" placeholder="Price" name='price' required={true}/>
              </div>
              <div className="modal__input__box">
                  <label htmlFor="">Qty</label>
                  <input className="modal__input" type="Number" placeholder="Qty" name='qty' required={true} />
              </div>
              {isTax && <div className="modal__input__box">
                  <label htmlFor="">Tax Rate</label>
                  <input className="modal__input" type="Number" placeholder="Tax Rate" name='taxrate' required={true} />
              </div>}
              <div className="modal__input__box">
                  <label htmlFor="">Discount Rate</label>
                  <input className="modal__input" type="Number" placeholder="Discount Rate" name='discountrate' required={true} />
              </div>  
              <button className="modal__save"  >Save</button>
          </form>  
  </div> ) :""
        }
          
          <div className="back__buton">
            <button onClick={()=>navigate("/invoices")}><KeyboardBackspaceOutlined/>Invoices</button>
          </div>
          <div className="title">
            {title}
          </div>
          <div className="invoice__box">
            <form action="" className='invoice__input__form'>
              <div className="invoice__input__box">
                <div className="invoice__input">
                    <label>Header</label>
                    <select name="header" onChange={handleHeaderSelect}>
                      
                      {
                        headerData?.map((header)=>{
                          return (
                            <option key={header._id}  value={header._id}>{header.name}</option>
                          )
                        })

                      }
                      <option selected={true} > Select</option>
                    </select>
                </div>
                <div className="invoice__input">
                    <label>Invoice No#</label>
                    <input type="text" disabled={true} placeholder={`${invoiceNo?.invoiceprefix}${invoiceNo.invoiceid.length+1}`}   />
                </div>=
                
              </div>
              
              <h3 style={{margin:"10px 10px"}}>Customer Details</h3>
              <hr style={{margin:"10px 10px",borderColor:"#EAECF0"}} />
              
              <div className="invoice__input__box">
                <div className="invoice__input" style={{position:"relative"}}>
                    <label>Name</label>
                    <input type="text" onFocus={handleDisplay} value={searchInputValue} onChange={(e)=>handleSearchInputChange(e)} />
                    <div style={{display: searchDisplay ? "block" : "none", zIndex:1000,padding:"5px", border:"1px solid teal", borderRadius:"5px",position:"absolute",marginTop:"65px",backgroundColor:"white",width:"96%",height:"20vh",overflow:"scroll"}}>
                      <ul >
                        {
                          searchData?.map((customer)=>  {
                            return <li key={customer._id} className="search__select__li" onClick={()=>{setSearchInputValue(customer.name);
                              setSearchDisplay(!searchDisplay);
                              setCurrentCustomer(customerData.filter((client)=> client.name.toLowerCase()===customer.name.toLowerCase()));}} >{customer.name}</li>
                          })
                        }
                        
                      </ul>
                    </div>
                </div>
                <div className="invoice__input">
                    <label>Address</label>
                    <input type="text" disabled={true} placeholder={currentCustomer[0]?.address}/>
                </div>
                
              </div>
              <div className="invoice__input__box">
                <div className="invoice__input">
                    <label>State</label>
                    <input type="text" disabled={true} placeholder={currentCustomer[0]?.state} />
                </div>
                <div className="invoice__input">
                    <label>Pincode</label>
                    <input type="text" disabled={true} placeholder={currentCustomer[0]?.pincode} />
                </div>
                
              </div>
              <div className="invoice__input__box">
                <div className="invoice__input">
                    <label>GST No</label>
                    <input type="text" disabled={true} placeholder={currentCustomer[0]?.gstno} />
                </div>
                <div className="invoice__input">
                    <label>PAN No</label>
                    <input type="text" disabled={true} placeholder={currentCustomer[0]?.panno} />
                </div>
                
              </div>
              <div className="invoice__input__box">
                <div className="invoice__input">
                    <label>Contact Person</label>
                    <input type="text" disabled={true} placeholder={currentCustomer[0]?.contactpersonname} />
                </div>
                <div className="invoice__input">
                    <label>Contact No</label>
                    <input type="text" disabled={true} placeholder={currentCustomer[0]?.contactpersonnumber} />
                </div>
                
              </div>
              <div className="invoice__input__box">
                <div className="invoice__input">
                    <label>Issued Date</label>
                    <input type="date" required={true} onChange={(e)=>setIssuedDate(e.target.value)}/>
                </div>
                <div className="invoice__input">
                    <label>Due Date</label>
                    <input type="date" required={true} value={duedate} onChange={(e)=>{
                      if(issueddate>e.target.value){
                        setDueDate(issueddate);
                        alert("Due date should be equal to or greater than due date");
                      }else{
                        setDueDate(e.target.value);
                      }
                    }}/>
                </div>
                
              </div>
              <div className="invoice__input__box">
                <div className="invoice__input" style={{flexDirection:"row",marginTop:"15px"}}>
                    <label>Is Tax Applicable ?</label>
                    <input type="checkbox" onClick={()=>setIsTax(!isTax)} />
                </div>
                <div className="invoice__input" style={{flexDirection:"row",marginTop:"15px"}}>
                    <label>Is Inter State ?</label>
                    <input type="checkbox" disabled={!isTax} onClick={()=>setIsInterState(!isInterState)}/>
                </div>
                
              </div>
              <h3 style={{margin:"10px 10px"}}>Item Details</h3>
              <hr style={{margin:"10px 10px",borderColor:"#EAECF0"}} />
              <div className="item__container" >
              <TableContainer component={Paper} className='table'>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      
                      <TableCell className='tableCell'>Name</TableCell>
                      <TableCell className='tableCell'>Price</TableCell>
                      <TableCell className='tableCell'>Qty</TableCell>
                      <TableCell className='tableCell'>Total Amt.</TableCell>
                      {isTax && <TableCell className='tableCell'>Tax Rate</TableCell>}
                      <TableCell className='tableCell'>Discount Rate</TableCell>
                      <TableCell className='tableCell'>Net Amt.</TableCell>
                      <TableCell className='tableCell'></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modalData?.map((row,i) => (
                      <TableRow
                        key={i}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell className='tableCell'>
                          {row.price}
                          </TableCell>
                        <TableCell className='tableCell'>{row.qty}</TableCell>
                        <TableCell className='tableCell'>{row.qty * row.price}</TableCell>
                        { isTax && <TableCell className='tableCell'>{row.taxrate/100}</TableCell>}
                        <TableCell className='tableCell'>{row.discountrate/100}</TableCell>
                        <TableCell className='tableCell'>
                          {isTax ? ((row.qty *row.price)-((row.qty * row.price)*(row.discountrate/100)))*(1+(row.taxrate/100)):((row.qty *row.price)-((row.qty * row.price)*(row.discountrate/100)))}
                          </TableCell>
                          <TableCell className='tableCell' component="th" scope='row' onClick={(e)=>handleDelete(e,row.name)}><DeleteOutline className='delete__btn' /></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
               
              </div>
              <div className="back__buton">
                  <button onClick={handleNewRow}><AddOutlined/>Add Item</button>
              </div>
              <div className="invoice__totals">
                <div className="invoice__note">
                  <textarea name="notes" id=""  rows="10" placeholder='Your Note....' onChange={(e)=>setNotes(e.target.value)}></textarea>
                </div>
                <div className="invoice__input__box">
                <div className="invoice__total__input">
                  <label htmlFor="">Total Value</label>
                  <input type="text" disabled={true} placeholder={totaltaxablevalue } />
                </div>
                <div className="invoice__total__input">
                  <label htmlFor="">Total Discount</label>
                  <input type="text" disabled={true} placeholder={totaldiscount } />
                </div>
                
                { isTax && isInterState ? (<div className="invoice__total__input">
                  <label htmlFor="">Total IGST</label>
                  <input type="text" disabled={true} placeholder={totaligst } />
                </div>):""}
                {isTax &&  !isInterState ? (<div className="invoice__total__input">
                  <label htmlFor="">Total CGST</label>
                  <input type="text" disabled={true} placeholder={totalcgst } />
                </div>):""}
                {isTax && !isInterState ? (<div className="invoice__total__input">
                  <label htmlFor="">Total SGST</label>
                  <input type="text" disabled={true} placeholder={totalsgst}/>
                </div>):""}
                <div className="invoice__total__input">
                  <label htmlFor="">Total Net Payable</label>
                  <input type="text" disabled={true} placeholder={totaltaxablevalue - totaldiscount + totalcgst+totaligst+totalsgst} />
                </div>
                </div>
                


              </div>
              <hr style={{margin:"10px 10px",borderColor:"#EAECF0"}} />
                <div className="create__invoice__buton" >
                  <button onClick={createInvoice} >Create Invoice</button>
                </div>
            </form>
          </div>
        </div>
    </Layout>
    </>
  )
}

export default Newinvoice