import { PieChart, Pie, Cell,Tooltip } from 'recharts';


const PieView =({data,COLORS}) => {

    
      
    return (
        <>     
                    <PieChart width={200} height={170} >
                          <Pie
                            data={data}
                            cx={80}
                            cy={80}
                            innerRadius={45}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                          >
                            {data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip/>
                      </PieChart>
                 
        </>
    )
}

export default PieView