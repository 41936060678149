import React, { useState, useEffect } from 'react'
import "./createheader.scss"
import Layout from '../../components/Layout/Layout'
import axios from 'axios'
import Datatable from '../../components/Datatable/Datatable'

const CreateHeader = () => {

    const [headerData, setHeaderData] = useState({
        name:"",
        title:"",
        cinno:"",
        gstno:"",
        panno:"",
        contactno:"",
        email:"",
        invoiceprefix:"",
        bankname:"",
        bankaccount:"",
        ifsccode:"",
        address:""
    });
    const [tableData, setTableData] = useState([]);
    const columns  = [
        {field:"_id", headerName: 'ID', width:70},
        {field:"name", headerName: 'Name', width: 70},
        {field:"title", headerName: 'Title', width: 170},
        {field:"cinno", headerName: 'CIN NO', width: 100},
        {field:"gstno", headerName: 'GST NO', width: 100},
        {field:"panno", headerName: 'PAN NO', width: 100 },
        {field:"contactno", headerName: 'CONTACT NO', width: 170},
        {field:"email", headerName: 'EMAIL', width: 170 },
        {field:"invoiceprefix", headerName: 'PREFIX', width: 100 },
        {field:"bankname", headerName: 'BANKNAME', width: 200 },
        {field:"bankaccount", headerName: 'BANKACCOUNT', width: 150 },
        {field:"ifsccode", headerName: 'IFSC CODE', width: 100 },
        {field:"address", headerName: 'ADDRESS', width: 300 },
    ]

    const handleChange = (e) => {
        e.preventDefault();
        setHeaderData((prev)=> ({...prev,[e.target.name]:e.target.value}));
        
    }
    const token = sessionStorage.getItem('token');
    const api = "https://herokumastercms.herokuapp.com/api/v1/headers/";

    const handleSubmit = async(e) => {
        e.preventDefault();
       
        
       await axios.post(api,headerData,{headers:{'authorization':token}})
        .then((res)=>alert("header created successfully"))
        .catch(((error)=>alert("Something went wrong please try again later")));
       
        setHeaderData({
            name:"",
            title:"",
            cinno:"",
            gstno:"",
            panno:"",
            contactno:"",
            email:"",
            invoiceprefix:"",
            bankname:"",
            bankaccount:"",
            ifsccode:"",
            address:""
        });
        
    }

    const getData =async()=>{
        await axios.get(api,{headers:{'authorization':token}})
        .then((res)=>{
            setTableData(res.data);
        })
    }

    useEffect(() => {
      getData();
    }, [])
    
    
   
  return (
    <>
        <Layout>
            <div className="header__container">
                <div className="title">
                            <h1>Create Header</h1>
                </div>
                <div className="form__box">
                    
                    <form onSubmit={handleSubmit}>
                        <div className="input__container">
                            <label >Name<span style={{color:"red"}}>*</span> </label>
                            <input type="text" name="name" onChange={handleChange} required={true}/>
                        </div>
                        <div className="input__container">
                            <label >Title<span style={{color:"red"}}>*</span></label>
                            <input type="text" name="title" onChange={handleChange} required={true}/>
                        </div>
                        <div className="input__container">
                            <label >CIN No(if company)</label>
                            <input type="text" name="cinno" onChange={handleChange}/>
                        </div>
                        <div className="input__container">
                            <label >GST No(if registered)</label>
                            <input type="text" name="gstno"onChange={handleChange} />
                        </div>
                        <div className="input__container">
                            <label >PAN No<span style={{color:"red"}}>*</span></label>
                            <input type="text" name="panno"onChange={handleChange} required={true}/>
                        </div>
                        <div className="input__container">
                            <label >Contact No<span style={{color:"red"}}>*</span></label>
                            <input type="text" name="contactno" onChange={handleChange} required={true}/>
                        </div>
                        <div className="input__container">
                            <label >Email<span style={{color:"red"}}>*</span></label>
                            <input type="text" name="email" onChange={handleChange} required={true}/>
                        </div>
                        <div className="input__container">
                            <label >Invoice Prefix<span style={{color:"red"}}>*</span></label>
                            <input type="text" name="invoiceprefix" onChange={handleChange} required={true}/>
                        </div>
                        <div className="input__container" >
                            <label htmlFor="">Address<span style={{color:"red"}}>*</span></label>
                            <input type="text" name="address" onChange={handleChange} required={true}/>
                        </div>
                        <div className="input__container">
                            <label >Bank Name<span style={{color:"red"}}>*</span></label>
                            <input type="text" name="bankname" onChange={handleChange} required={true}/>
                        </div>
                        <div className="input__container">
                            <label >Bank A/c<span style={{color:"red"}}>*</span></label>
                            <input type="text" name="bankaccount" onChange={handleChange} required={true}/>
                        </div>
                        <div className="input__container">
                            <label >IFSC Code<span style={{color:"red"}}>*</span></label>
                            <input type="text" name="ifsccode" onChange={handleChange} required={true}/>
                        </div>
                        
                        <button className='header__submit__btn' type="submit">Save</button>
                    </form>
                </div>
                <div>
                    {
                      tableData &&   <Datatable dataColumns={columns} dataRows={tableData} />
                    }
                </div>
            </div>
        </Layout>
    </>
  )
}

export default CreateHeader