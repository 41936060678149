import React from 'react'
import { useNavigate } from 'react-router-dom'


const Autherror = () => {
    const navigate = useNavigate();
  return (
    <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
        <h1 style={{fontSize:"150px",color:"teal"}}><span style={{color:"#E893A6"}}>4</span>0<span style={{color:"#E893A6"}}>3</span> </h1>
        <img src="https://cdn.dribbble.com/users/2149912/screenshots/7847664/media/37b2ee322d95b8fd9a3aa750a52342be.png?compress=1&resize=400x300&vertical=top" alt="access denied" />
        
        <h3 style={{textAlign:"center",marginBottom:"10px",color:"teal"}}>You do not have permissions to access this page . <br />Please contact your Site Administrator to request access</h3>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            <button 
            style={{border:"1px solid teal", color:"teal",padding:"10px",borderRadius:"5px",margin:"0px 10px",background:"none",cursor:"pointer"}}
            onClick={()=> navigate("/") }
            >Go Back</button>
            
        </div>
    </div>
  )
}

export default Autherror