import React,{useState} from 'react'
import './forminput.css'

const FormInput = (props) => {

  const {label,id,onChange,errormessage,...rest} = props ; 

  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true) ;
  }
  return (
    <div className='form__input'>
        <label>{label}</label>
        <input {...rest} onChange={onChange} onBlur={handleFocus} focused={focused.toString()} onFocus={()=>rest.name === "confirmpassword" && setFocused(true)}/>
        <span className='errormessage'>{errormessage}</span>
    </div>
  )
}

export default FormInput