
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import { templateColumns } from "../../datatablesource.js";
import "./com.scss"


const Com = () => {
    const [loading, setLoading] = useState(false);
    const [tempTableData, setTempTableData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [currentId, setCurrentId] = useState("");


    const [tempData, setTempData] = useState({
        name: "",
        message: ""
    });

    const handleChange = (e) => {
        e.preventDefault();
        setTempData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }


    const handleEdit =(id) => {
        
        const currentTempData = tempTableData.filter(temp => { return temp._id === id }) ;
        setTempData(currentTempData[0]);
        setIsEdit(true);
        setCurrentId(id);
    }
    
    const handleEditSave =async() =>{
        const token = sessionStorage.getItem("token");
        const api = `https://herokumastercms.herokuapp.com/api/v1/templates/${currentId}`;
        const res = await axios.put(api,tempData, { headers: { 'authorization': token } });
        const getApi = "https://herokumastercms.herokuapp.com/api/v1/templates/";
        const getRes = await axios.get(getApi, { headers: { 'authorization': token } });
        setTempTableData(getRes.data);
        setTempData(prev=>({...prev,name:"",message:""}));
        setIsEdit(false);
        window.location.reload();
        
    }

    const handleDelete = async (id) => {
        const token = sessionStorage.getItem('token');
        const api = `https://herokumastercms.herokuapp.com/api/v1/templates/${id}`;
        const getApi = "https://herokumastercms.herokuapp.com/api/v1/templates/"
        const res = await axios.delete(api, { headers: { 'authorization': token } });
        alert(res.data);
        const getRes = await axios.get(getApi, { headers: { 'authorization': token } });
        setTempTableData(getRes.data);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const token = sessionStorage.getItem('token');
        const api = "https://herokumastercms.herokuapp.com/api/v1/templates";
        const res = await axios.post(api, tempData, { headers: { 'authorization': token } });
        alert("Template Created Successfully");
        setLoading(false);
        window.location.reload();
    }

    const actionColumn = [{
        field: "action", headerName: "Action", width: 200, renderCell: (params) => {
            return (
                <div className='cell__action'>
                    <button className="view__button" onClick={() => handleEdit(params.row._id)}>
                        Edit
                    </button>
                    <div className='delete__button' onClick={() => handleDelete(params.row._id)}>
                        Delete
                    </div>
                </div>
            )
        }
    }]

    useEffect(()=>{
        const fetchData = async () => {
            const token = sessionStorage.getItem('token');
            const api = "https://herokumastercms.herokuapp.com/api/v1/templates/";
            const res = await axios.get(api, { headers: { "authorization": token } });
            setTempTableData(res.data);
            console.log(res.data);
        }
    
        fetchData();

    },[]);
    


    return (
        <>
            <Layout>
                <div className="com__main">
                    <div className="com__container">
                        <div className="title">
                            <h2>Create and Edit Template</h2>

                        </div>
                        <div className="form__box">
                            {tempData && <form onSubmit={handleSubmit} className="input__form">
                                <div className="input__container">
                                    <label>Name</label>
                                    <input type="text" required={true} name="name" onChange={handleChange} placeholder={tempData.name !=="" ? tempData.name : "Name"} />
                                </div>
                                <div className="input__container">
                                    <label>Message Body</label>
                                    <input type="text" required={true} name="message" onChange={handleChange} placeholder={tempData.message !=="" ? tempData.message: "Message" } />
                                </div>
                                {
                                    
                                        isEdit ? "" : (<button type='submit' className='submit_btn'>{loading ? "Wait..." : "Save"}</button>)
                                }

                            </form>}
                            
                        </div>
                        <div className="edit__btn__box">
                            {
                            isEdit ? (<button type='submit' onClick={handleEditSave} className='edit_btn'>Edit</button>) :""
                            }
                        </div>
                        <h2 style={{padding:"0px 10px"}}>All Templates</h2>
                            
                        <div className="table__data">
                            {tempTableData && <DataGrid
                                rows={tempTableData}
                                getRowId={(row) => row._id}
                                columns={templateColumns.concat(actionColumn)}
                                pageSize={9}
                                rowsPerPageOptions={[9]}
                                checkboxSelection
                            />
                            }
                        </div>


                    </div>
                </div>
            </Layout>
        </>
    )
}


export default Com;