import React,{useEffect,useState} from 'react'
import "../singlecomservice/singlecomservice.scss"
import Sidebar from "../../components/Sidebar/Sidebar"
import Navbar from "../../components/Navbar/Navabar"
import Chart from "../../components/Chart/Chart"
// import TableView from '../../components/Table/TableView'
import axios from "axios"
import { useLocation} from 'react-router-dom'
import { comserviceInput } from '../../formSource'

const SingleComservice = () => {
  const [comserviceData , setComserviceData] = useState([]);
  const [edit,setEdit] = useState(false);
  const history = useLocation();
  console.log(history.pathname);
  const api = `https://herokumastercms.herokuapp.com/api/v1${history.pathname}`;

  const putApi = `https://herokumastercms.herokuapp.com/api/v1${history.pathname}`;

  const handleClick = () =>{
    setEdit(!edit);

  }
  const handleSaveBtn = () => {
    setEdit(!edit);
  }

  const handleSave = (e) =>{
    e.preventDefault();
    
    const token = sessionStorage.getItem('token');
    axios.put(putApi,comserviceData,{headers: {'authorization':token}})
    .then((res)=>{setComserviceData(res.data)}).catch((err)=>{
      console.log(err);
    })
    setEdit(!edit);
    alert("data saved successfully");
  }


  useEffect(()=>{
    const token = sessionStorage.getItem('token');
    axios.get(api,{headers: {'authorization':token}})
    .then((res)=>{setComserviceData(res.data)})
  },[]);


  return (
    <div className='single'>
      <Sidebar/>
      <div className="single__container">
        <Navbar/>
        <div className='top'>
          <div className="left">
            <div className="edit__button" onClick={handleClick}>
              Edit
            </div>
            <h1 className="title">
              Information
            </h1>
            <div className='img__container'>
              <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80" alt="itemimg" className='item__image'/>
              <div className="details">
                <h1 className='item__title'>{comserviceData?.name}</h1>
                <div className="detail__item">
                  <span className="item__key">Type</span>
                  <span className="item__value">{comserviceData?.type}</span>
                </div>
                <div className="detail__item">
                  <span className="item__key">API Key</span>
                  <span className="item__value">{comserviceData?.apikey}</span>
                </div>
                <div className="detail__item">
                  <span className="item__key">Base URL</span>
                  <span className="item__value">{comserviceData?.baseurl}</span>
                </div>
                <div className="detail__item">
                  <span className="item__key">Status</span>
                  <span className="item__value">{comserviceData?.status}</span>
                </div>
              </div>
            </div>
          </div>
         
          {
            edit ? (<div className='right'>
            <form onSubmit={(e)=>handleSave(e)}>
              {
                comserviceInput.map((service)=>{
                  return (
                    <div className="form__input" key={service.id}>
                      <label>{service.label}</label>
                      <input type={service.type} placeholder={service.placeholder}  name={service.name} onChange={(e)=>comserviceData[service.name] = e.target.value} />
                    </div>
                  )
                })
              }
                
                
                <button style={{width:"100%",padding:"10px", background:"none",color:"white",backgroundColor:"teal",fontWeight:"700",borderRadius:"5px",borderWidth:"1px",borderColor:"lightgray"}} >SAVE</button>
            </form>
        </div>) :( <div className="right">
            <Chart aspect={3 / 1}  title="User Spending (Last 6 Months)"/>
          </div>)
          }
          
        </div>
        <div className='bottom'>
          <div className="title">
            Last Transaction
          </div>
          {/* <TableView/> */}
        </div>
      </div>

    </div>
  )
}

export default SingleComservice