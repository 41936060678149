export const userInput = [
    {
        id:1,
        label:"Username",
        type:"text",
        name:"username",
        placeholder:"john_doe",
    },
    
    {
        id:2,
        label:"Email",
        type:"mail",
        name:"email",
        placeholder:"johndoe@gmail.com",
    },
    
    {
        id:3,
        label:"Password",
        type:"password",
        name:"password"
    },
    {
        id:4,
        label:"Is Admin",
        type:"text",
        name:"isAdmin",
        placeholder:"Y/N",
    }
    
]


export const productInput = [
    {
        id:1,
        label:"Product Name",
        type:"text",
        name:"name",
        placeholder:"LCD TV"
    },
    {
        id:2,
        label:"Description",
        name:"description",
        type:"text",
        placeholder:"This is best product in the world"
    },
    {
        id:3,
        label:"Category",
        name:"type",
        type:"text",
        placeholder:"Electronics"
    },
    {
        id:4,
        label:"Price",
        type:"text",
        name:"baseprice",
        placeholder:"100"
    },
    {
        id:5,
        label:"Stock",
        type:"number",
        name:"balance",
        placeholder:"In Stock"
    }
]

export const customerInput = [
    {
        id:1,
        label:"Name",
        type:"text",
        name:"name",
        placeholder:"john_doe",
    },
    {
        id:2,
        label:"Address",
        type:"text",
        name:"address",
        placeholder:"John Doe",
    },
    {
        id:3,
        label:"City",
        type:"text",
        name:"city",
        placeholder:"Delhi",
    },
    {
        id:4,
        label:"State",
        type:"text",
        name:"state",
        placeholder:"Delhi",
    },
    {
        id:5,
        label:"Pincode",
        type:"number",
        name:"pincode",
        placeholder:"110040",
    },
    {
        id:6,
        label:"GST NO",
        type:"text",
        name:"gstno",
        placeholder:"07ABCDE1234AZA1A",
    },
    {
        id:7,
        label:"PAN NO",
        type:"text",
        name:"panno",
        placeholder:"ABCDE1234A"
    },
    {
        id:8,
        label:"Contact Person Name",
        type:"text",
        name:"contactpersonname",
        placeholder:"Bhagwan"
    },
    {
        id:9,
        label:"Contact Person Phone Number",
        type:"text",
        name:"contactpersonnumber",
        placeholder:"9123312345"
    },
    {
        id:10,
        label:"Comservice",
        type:"text",
        name:"comservice",
        placeholder:"Gmail"
    }
]

export const comserviceInput = [
    {
        id:1,
        label:"Name",
        type:"text",
        name:"name",
        placeholder:"gmail, call,message etc."
    },
    {
        id:2,
        label:"Type",
        type:"text",
        name:"type",
        placeholder:"Mail,Message,Call"
    },
    {
        id:3,
        label:"Api Key",
        type:"text",
        name:"apikey",
        placeholder:"Enter api key provided by api provider"
    },
    {
        id:4,
        label:"Base URL",
        type:"text",
        name:"baseurl",
        placeholder:"www.abc.com/sentmessage"
    },
    {
        id:5,
        label:"Status",
        type:"text",
        name:"status",
        placeholder:"Active or Deactive"
    }
    
]