import React,{useEffect,useState} from 'react'
import "../single/Single.scss"
import Sidebar from "../../components/Sidebar/Sidebar"
import Navbar from "../../components/Navbar/Navabar"
import Chart from "../../components/Chart/Chart"
// import TableView from '../../components/Table/TableView'
import axios from "axios"
import { useLocation} from 'react-router-dom'
import { userInput } from '../../formSource'
// import { userColumns } from '../../datatablesource'
// import { userRows } from '../../datatablesource'

const Single = () => {
  const [userData , setUserData] = useState([]);
  const [edit,setEdit] = useState(false);
  const history = useLocation();
  // console.log(history.pathname);
  const api = `https://herokumastercms.herokuapp.com/api/v1${history.pathname}`;

  const putApi = `https://herokumastercms.herokuapp.com/api/v1${history.pathname}`;
  

  const handleClick = () =>{
    setEdit(!edit);

  }

  

  const handleSave = (e) =>{
    e.preventDefault();
    // console.log(userData);
    
    const token = sessionStorage.getItem('token');
    axios.put(putApi,userData,{headers: {'authorization':token}})
    .then((res)=>{
      setUserData(res.data);
      alert("data saved successfully");
    })
    .catch((err)=>{
      console.log(err);
    })
    setEdit(!edit);
    
  }




  useEffect(()=>{
    const token = sessionStorage.getItem('token');
    axios.get(api,{headers: {'authorization':token}})
    .then((res)=>{setUserData(res.data)})
  },[]);
  return (
    <div className='single'>
      <Sidebar/>
      <div className="single__container">
        <Navbar/>
        <div className='top'>
          <div className="left">
            <div className="edit__button" onClick={handleClick}>
              Edit
            </div>
            <h1 className="title">
              Information
            </h1>
            <div className='img__container'>
              <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80" alt="itemimg" className='item__image'/>
              <div className="details">
                <h1 className='item__title'>{userData.username}</h1>
                <div className="detail__item">
                  <span className="item__key">Email:</span>
                  <span className="item__value">{userData.email}</span>
                </div>
                
                <div className="detail__item">
                  <span className="item__key">IsAdmin</span>
                  <span className="item__value">{userData.isAdmin}</span>
                </div>
                
              </div>
            </div>
          </div>
          {
            edit ? (<div className='right'>
            <form onSubmit={handleSave}>
              {
                
                userInput.map((user)=>{
                  return (
                    <div className="form__input" key={user.id}>
                      <label>{user.label}</label>
                      <input type={user.type} placeholder={userData[user.name]} required name={user.name} onChange={(e)=>userData[user.name] = e.target.value} />
                    </div>
                  )
                })
              }
                
                
                <button style={{width:"48%",padding:"10px", background:"none",color:"white",backgroundColor:"teal",fontWeight:"700",borderRadius:"5px",borderWidth:"1px",borderColor:"lightgray"}} >SAVE</button>
            </form>
        </div>) :( <div className="right">
            <Chart aspect={3 / 1}  title="User Spending (Last 6 Months)"/>
          </div>)
          }
        </div>
        <div className='bottom'>
          <div className="title">
            Last Transaction
          </div>
          {/* <TableView tablehead={userColumns} row ={userRows}/> */}
        </div>
      </div>

    </div>
  )
}

export default Single