import React,{useEffect, useState} from 'react'
import "../ImportCustomer/import.css"
import { DataGrid} from '@mui/x-data-grid';
import { customerColumns,userColumns,productColumns,comserviceColumns } from '../../datatablesource';
import * as xlsx from "xlsx";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Import = ({pathname}) => {
    const [data,setData] = useState([]);
    const navigator = useNavigate();
    const history = useLocation();
    const historyLength = history.pathname.length ;
    const navigatorPath  = history.pathname.slice(0,historyLength-11);
    console.log(navigatorPath);


    let importdata;
    switch(pathname){
        case "/users/importfile" :
            importdata = {columns:userColumns};
        break;
        case "/customers/importfile" :
            importdata = {columns:customerColumns};
        break;
        case "/items/importfile" :
            importdata = {columns:productColumns};
        break;
        case "/comservices/importfile" :
            importdata = {columns:comserviceColumns};
        break;
        default:
            break;
    }
    

    const [isLoading,setIsLoading] = useState(false);

    const handleTable = (sheetdata) =>{
        // console.log(Object.keys(sheetdata[0]).length);
        setData(sheetdata);
        setIsLoading(false);
        alert(`Imported ${sheetdata.length} records successfully`)
    }

    const readUploadFile = (e) => {
        setIsLoading(true);
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                // console.log(json.flat());
                const finalJson = json.flat();
                const dataLength = Object.keys(finalJson[0]).length;
                const columnsLength = Object.keys(importdata.columns).length;
                if(dataLength === columnsLength){
                    handleTable(finalJson);
                }else{
                    console.log(dataLength);
                    console.log(columnsLength);
                    alert("Please upload file in required format");
                    setIsLoading(false);
                }
                
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }
    
    const handleSave = (e) => {
        e.preventDefault();
        const token = sessionStorage.getItem('token');
        // console.log(sessionStorage);
        const path = history.pathname;
        // console.log(path)

        const api = `https://herokumastercms.herokuapp.com/api/v1${path}`;
            axios.post(api,data,{headers: {'authorization':token}})
            .then((res)=>{
                setTimeout(navigator(navigatorPath,{replace:true}), 10000);
                
            })
            .catch((err)=>{
                if(err.response.data.code===11000){alert("Sorry! we can't save as user already exists")}
            }); 
    }

  return (
    <>
        <form className='file__upload__form'>
            <label htmlFor="upload" className='upload__label'>Upload File</label>
            <input 
                type="file" 
                name='upload' 
                id='upload' 
                onChange={readUploadFile} />
            
        </form>
        {
            isLoading ? "Please wait we are uploading your files...."  : <div className='datatable'>
            <DataGrid
              rows={data}
              columns={importdata.columns}
              pageSize={9}
              rowsPerPageOptions={[9]}
              checkboxSelection
            />
          </div>
          
        }
        <button className='save__btn' onClick={handleSave}>Save</button>
        
       
    </>
  )
}

export default Import