
export const userColumns = [
  { field: '_id', headerName: 'ID', width: 70 },
  {
    field:"username",
    headerName:"User Name",
    width:230,
    // renderCell :(params) => {
    // return (
    // <div className="cell__with__image">
    //     <img src={params.row.img} alt="avatar" className="cell__img"/>
    //     {params.row.username}
    // </div>
    // );
    // }
},
{ field: 'email', headerName: 'Email', width: 200 },
{ field: 'password', headerName: 'Password', width: 150 },
{
    field:"isAdmin",
    headerName:"Is Admin",
    width:100,
    // renderCell :(params) => {
    // return (
    // <div className={`cell__with__status ${params.row.status}`}>
    //     {params.row.status}
    // </div>
    // );
    // }
},

  
];

export const customerColumns = [
  { field: '_id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 200 },
  {
    field:"address",
    headerName:"Address",
    width:300,
    
},
{ field: 'city', headerName: 'City', width: 150 },
{ field: 'state', headerName: 'State', width: 150 },
{
    field:"pincode",
    headerName:"Pincode",
    width:100,
},
{
  field:"gstno",
  headerName:"GST NO",
  width:150,
},
{
  field:"panno",
  headerName:"PAN NO",
  width:150,
},
{
  field:"cpname",
  headerName:"Contact Person Name",
  width:200,
},
{
  field:"cpnumber",
  headerName:"Contact Person Mobile No",
  width:150,
},
{
  field:"comservice",
  headerName:"Comservice",
  width:150,
},

  
];
export const productColumns = [
  { field: '_id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 300 },
  {
    field:"type",
    headerName:"Type",
    width:120,
    
},
{ field: 'baseprice', headerName: 'Base Price', width: 150 },
  
];
export const comserviceColumns = [
  { field: '_id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 300 },
  {
    field:"type",
    headerName:"Type",
    width:120,
    
},
{ field: 'apikey', headerName: 'Api Key', width: 150 },
  
];
export const templateColumns = [
  { field: '_id', headerName: 'ID', width: 120 },
  { field: 'name', headerName: 'Name', width: 200 },
  {
    field:"message",
    headerName:"Message Body",
    width:500
  },
  
];



//temporary data
export const userRows = [
    {
      id: 1,
      username: "Snow",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      status: "active",
      email: "1snow@gmail.com",
      age: 35,
    },
    {
      id: 2,
      username: "Jamie Lannister",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "2snow@gmail.com",
      status: "passive",
      age: 42,
    },
    {
      id: 3,
      username: "Lannister",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "3snow@gmail.com",
      status: "pending",
      age: 45,
    },
    {
      id: 4,
      username: "Stark",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "4snow@gmail.com",
      status: "active",
      age: 16,
    },
    {
      id: 5,
      username: "Targaryen",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "5snow@gmail.com",
      status: "passive",
      age: 22,
    },
    {
      id: 6,
      username: "Melisandre",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "6snow@gmail.com",
      status: "active",
      age: 15,
    },
    {
      id: 7,
      username: "Clifford",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "7snow@gmail.com",
      status: "passive",
      age: 44,
    },
    {
      id: 8,
      username: "Frances",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "8snow@gmail.com",
      status: "active",
      age: 36,
    },
    {
      id: 9,
      username: "Roxie",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "snow@gmail.com",
      status: "pending",
      age: 65,
    },
    {
      id: 10,
      username: "Roxie",
      img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      email: "snow@gmail.com",
      status: "active",
      age: 65,
    },
  ];