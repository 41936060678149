import React,{useState,useEffect} from 'react'
import axios from "axios"
import Navabar from '../../components/Navbar/Navabar'
import Sidebar from '../../components/Sidebar/Sidebar'
import {Add} from "@mui/icons-material"
import PieView from "../../components/PieChart/Pie"
import "./invoice.scss"
import BasicTabs from '../../components/Tabs/TabView'
import Datatable from '../../components/Datatable/Datatable'
import { useNavigate } from 'react-router-dom'


const InvociePage = () => {

  const navigate = useNavigate();
  const currentDateString = new Date() ;
  
  const currentDate = currentDateString.getFullYear()+"-"+(currentDateString.getMonth()+1 < 10 ? "0"+(currentDateString.getMonth()+1) :currentDateString.getMonth()+1)+"-"+currentDateString.getDate() ;

 
  const columns = [
    { field: '_id', headerName: 'ID'},
    { field: 'invoiceserial', headerName: 'No.',width:120 },
    { field: 'issueddate', headerName: 'Issued Date',width:115,
      renderCell:(params)=>{
        return (<div>{params.row.issueddate.slice(0,10)}</div>)
      }
    },
    { field: 'duedate', headerName: 'Due Date',width:115,
      renderCell:(params)=>{
        return (<div>{params.row.duedate.slice(0,10)}</div>)
      }
    },
    { field: 'customername', headerName: 'Customer Name',width:250},
    { field: 'headername', headerName: 'Header Name',width:180},
    
    {
      field: 'items',
      headerName: 'Items',
      renderCell:(params)=>{
        return (<div>{params.row.items.map(item=> <li>{item.name}</li>)}</div>)
      }
    },

    
    {
      field: 'nettotal',
      headerName: 'Net Total',
    
    },
    {
      field: 'paidamt',
      headerName: 'Paid',
      renderCell:(params)=>{
        return (<div>{params.row.paymentdetails?.paidamt}</div>)
      }
    
    },
    {
      field: 'paymentdiscount',
      headerName: 'Payment Discount',
      renderCell:(params)=>{
        return (<div>{params.row.paymentdetails?.paymentdiscount}</div>)
      }
    
    },
    {
      field: 'pendingamt',
      headerName: 'Pending Amount',
      renderCell:(params)=>{
        return (<div>{params.row.nettotal-params.row.paymentdetails?.paidamt-params.row.paymentdetails?.paymentdiscount}</div>)
      }
    
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell:(params)=>{
        return (<div>{params.row.paymentdetails?.pendingamount < 1 ? "Paid" : (params.row.duedate.slice(0,10) <currentDate ? "Overdue" : params.row.status)}</div>)
      }
    
    },
    
  ];
  
  
  const [invoiceData, setInvoiceData] = useState([]);
  const [tableData, setTableData] = useState([]);
  

  const categories = ["Show All","Paid","Pending","Draft","Overdue"];
  

  const data = [
    { name: 'Paid', value: 400 },
    { name: 'Pending', value: 300 },
    { name: 'Draft', value: 300 },
    { name: 'Overdue', value: 200 },
  ];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const handleTab = (tabvalue) =>{
  // console.log(tabvalue);
  if(tabvalue !== "show all"){
    const updatedData = invoiceData.filter((row) => {return row.status.toLowerCase() === tabvalue});
    // console.log(updatedData);
    setTableData(updatedData);

  }else{
    setTableData(invoiceData);
  }
}

const getData =async()=>{
  const api = "https://herokumastercms.herokuapp.com/api/v1/invoices";
  const token = sessionStorage.getItem("token");
  const data = [];
  await axios.get(api,{headers:{"authorization":token}})
    .then((res)=> {
      const invoices = res.data.a ;
      const customers = res.data.b ;
      const headers = res.data.c;
      // console.log(customers);
      invoices.map((invoice,i)=>{
        if( customers[i]!= null){
          data.push({...invoice,customername:customers ? customers[i].name : "",headername:headers ? headers[i]?.title : ""});
        }
      })
      
      // console.log(data);
      setInvoiceData(data);
      setTableData(data);
    });

}

useEffect(()=>{
  getData();
},[]);
 
  return (
    <div className='invoice'>
        <Sidebar/>
        <div className="invoice__container">
          <Navabar/>
          <div className="invoice__header__box">
            <h2 className='invoice__header__heading'>Invoices</h2>
            <button className='invoice__add__button' onClick={()=>navigate("/invoices/new")}><Add/>Add New</button>
          </div>
          <div className="invoice__analytics">
            <div className="invoice__heading__details">
              <div className="details__amt">
                <h4 style={{color:"#98A2B3",marginBottom:"5px"}}>TOTAL INVOICE VALUE</h4>
                <h1 style={{color:"#1D2939"}}>Rs.12,00,000</h1>
              </div>
              <span style={{color:"#98A2B3"}}>From total of 6 invoices</span>
            </div>
            <div className="invoice__heading_chart">
                <PieView data = {data} COLORS={COLORS}/>
                <ul>
                  {
                    data.map((item,i)=>{
                      return(
                        <li key={item._id}>
                        <span style={{color:`${COLORS[i]}`}}>{item.name}</span> : {item.value}
                      </li>
                      )
                    })
                  }
                  
                </ul>
            </div>
          </div>
          <div className="invoice__table">
            <BasicTabs categories={categories} handletab={handleTab}/>
            {
              tableData && <Datatable dataColumns={columns} dataRows={tableData}/>
            }
            {/* <MaterialTable title="Employee Data" data={empdata} columns={columns} /> */}

          </div>

        </div>
    </div>
  )
}

export default InvociePage