import React, { useEffect, useState } from 'react'
import "../singlecustomer/singlecustomer.scss"
import Sidebar from "../../components/Sidebar/Sidebar"
import Navbar from "../../components/Navbar/Navabar"
import axios from "axios"
import { useLocation } from 'react-router-dom'
import { customerInput } from '../../formSource'
import PieView from '../../components/PieChart/Pie'
import Datatable from '../../components/Datatable/Datatable'

const SingleCustomer = () => {
  const [customerData, setCustomerData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const tableHeader = [
    { field: '_id', headerName: 'ID', width: 170 },
    { field: 'invoiceserial', headerName: 'Invoice No', width: 170 },
    { field: 'issueddate', headerName: 'Issue Date', width: 115 },
    { field: 'duedate', headerName: 'Due Date', width: 115 },
    { field: 'nettotal', headerName: 'Net Total', width: 120 },
    {
      field: 'paymentdetails.paidamt', headerName: 'Paid Amount', width: 120,
      renderCell: (params) => {
        return (
          <div>

            {params.row.paymentdetails.paidamt}
          </div>
        );
      }
    }
  ]
  const piedata = [
    { name: 'Paid', value: 400 },
    { name: 'Pending', value: 300 },
    { name: 'Draft', value: 300 },
    { name: 'Overdue', value: 200 },
  ];

  const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  const [edit, setEdit] = useState(false);
  const history = useLocation();
  // console.log(history.pathname);
  const api = `https://herokumastercms.herokuapp.com/api/v1${history.pathname}`;

  const putApi = `https://herokumastercms.herokuapp.com/api/v1${history.pathname}`;

  const handleClick = () => {
    setEdit(!edit);
  }



  const handleSave = (e) => {
    e.preventDefault();
    // const formvalues = new FormData(e.target) ;
    // const data = Object.fromEntries(formvalues.entries());
    // console.log(data);

    const token = sessionStorage.getItem('token');
    axios.put(putApi, customerData, { headers: { 'authorization': token } })
      .then((res) => { setCustomerData(res.data) }).catch((err) => {
        console.log(err);
      })
    setEdit(!edit);
    alert("data saved successfully");
  }

  const getData = async () => {
    const token = sessionStorage.getItem('token');
    await axios.get(api, { headers: { 'authorization': token } })
    .then((res)=>{
      // console.log(res.data);
      if(res.data.customer !== undefined){
        setCustomerData(res.data.customer);
      }
      if(res.data.invoices !== undefined){
        setInvoiceData(res.data.invoices)
      }
    })

    
  }
      


  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='single'>
      <Sidebar />
      <div className="single__container">
        <Navbar />
        <div className='top'>
          <div className="left">
            <div className="edit__button" onClick={handleClick} >
              Edit
            </div>
            <h1 className="title">
              Information
            </h1>
            <div className='img__container'>

              {customerData && <div className="details">
                <h3 className='item__title'>{customerData.name}</h3>
                <div className="detail__item">
                  <span className="item__key">Address:</span>
                  <span className="item__value">{customerData.address},{customerData.state},{customerData.city},{customerData.pincode}</span>
                </div>
                <div className="detail__item">
                  <span className="item__key">Contact Person Name:</span>
                  <span className="item__value">{customerData.contactpersonname}</span>
                </div>
                <div className="detail__item">
                  <span className="item__key">Contact Person Number:</span>
                  <span className="item__value">{customerData.contactpersonnumber}</span>
                </div>
                <div className="detail__item">
                  <span className="item__key">GST No:</span>
                  <span className="item__value">{customerData.gstno}</span>
                </div>
                <div className="detail__item">
                  <span className="item__key">PAN No:</span>
                  <span className="item__value">{customerData.panno}</span>
                </div>
              </div>}
            </div>
          </div>
          {
            edit ? (<div className='right'>
              <form onSubmit={(e) => handleSave(e)}>
                {

                  customerInput.map((customer) => {
                    return (
                      <div className="form__input" key={customer.id}>
                        <label>{customer.label}</label>
                        <input type={customer.type} placeholder={customerData[customer.name]} name={customer.name} onChange={(e) => customerData[customer.name] = e.target.value} />
                      </div>
                    )
                  })
                }


                <button>SAVE</button>
              </form>
            </div>) : (<div className="right">
              <PieView data={piedata} COLORS={colors} title="User Spending (Last 6 Months)" />
              <ul>
                {
                  piedata.map((item, i) => {
                    return (
                      <li key={i}>
                        <span style={{ color: `${colors[i]}` }}>{item.name}</span> : {item.value}
                      </li>
                    )
                  })
                }

              </ul>
            </div>)
          }
        </div>
        <div className='bottom'>
          <div className="title">
            Last Transaction
          </div>
          {
            invoiceData && <Datatable dataColumns={tableHeader} dataRows={invoiceData} />

          }
          {/* <TableView tablehead={tableHeader} /> */}
        </div>
      </div>

    </div>
  )
}

export default SingleCustomer