import React from 'react'
import Navabar from '../Navbar/Navabar'
import Sidebar from '../Sidebar/Sidebar'
import './layout.css'

const Layout = ({children}) => {
  return (
    <>
    <div className="layout">
        <Sidebar/>
        <div className='children'>
            <Navabar/>
            <main>{children}</main>
        </div>
    </div>

    </>
  )
}

export default Layout