import React,{useState,useEffect} from 'react'
import "../new/New.scss"
import {useLocation, useNavigate} from "react-router-dom"
import Navbar from "../../components/Navbar/Navabar"
import Sidebar from "../../components/Sidebar/Sidebar"
import {DriveFolderUploadOutlined} from "@mui/icons-material"
import axios from 'axios'

const New = ({inputs, title}) => {

  const [file, setFile] = useState("");
  const history = useLocation();
  const navigator = useNavigate();
  const historyLength = history.pathname.length ;
  const navigatorPath  = history.pathname.slice(0,historyLength-4);
  // console.log(navigatorPath);
  // console.log(file);
  // console.log(title.split(" ").splice(2,1))

  const handleSubmit = (e) => {
    e.preventDefault();
    const formvalues = new FormData(e.target) ;
    const data = Object.fromEntries(formvalues.entries());
    const token = sessionStorage.getItem('token');
    // console.log(sessionStorage);
    const path = history.pathname.slice(0,historyLength-4);
    console.log(path)

    const api = `https://herokumastercms.herokuapp.com/api/v1${path}`;

    axios.post(api,data,{headers: {'authorization':token}}).then((res)=>{alert("data saved successfully");navigator(navigatorPath,{replace:true});}).catch((err)=>{
      if(err.response.data.code===11000){
        alert("Sorry! we can't save as user already exists");
      }
    });
  }

  return (
    <div className="new">
      <Sidebar/>
      <div className='new__container'>
        <Navbar/>
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          { title.split(" ").splice(2,1)[0] === "User" ?
            (<div className="left">
              <img src={file ? URL.createObjectURL(file) : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTunLJG8Tol7W_XP4NtQMMrXNjZTu7GRFKW_zt5lDQMPOthpElIgqkMPOdxLJ4r2TCdT3E&usqp=CAU"} alt="userimageicon" />
            </div> ) : "" 
          }
          
          <div className="right">
            <form onSubmit={handleSubmit}>
              {
                title.split(" ").splice(2,1)[0] === "User" ? (<div className="form__input">
                <label htmlFor='file' className='file__input'>
                 Upload Image:<DriveFolderUploadOutlined className='icon' />
                </label>
                <input type="file" id='file' style={{display:"none"}} onChange={(e)=>setFile(e.target.files[0])}/>
              </div>) :""
              }
              
              {
                inputs.map((item)=>{
                  return(
                    <div className="form__input" key={item.id}>
                        <label>{item.label}</label>
                        <input type={item.type} placeholder={item.placeholder} required={true} name={item.name} />
                    </div>
                  )
                })
              }
              <button>SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default New