import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { KeyboardBackspaceOutlined, Preview, EmailOutlined, WhatsappOutlined, MessageOutlined, CallMadeOutlined, PaymentOutlined, DownloadOutlined, DeleteOutlined, EditOutlined} from '@mui/icons-material'
import '../../pages/invoicedetails/invoicedetails.scss'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';


const InvoiceDetails = () => {

  const [template, setTemplate] = React.useState('');
  const [allTemplate,setAllTemplate] = useState([]);
  const [refetch,setRefetch] = useState(false);
  const navigate = useNavigate();
  const [paymentDetails,setPaymentDetails] = useState({
    dop:"",
    paidamt:0,
    paymentdiscount:0,
    pendingamt:0,
    paymentmethod:"",
    trax:"",
    traxfees:null,
    bankname:"",
    naration:""

  });

  const handleModalInputChange =(e) =>{
    e.preventDefault();
    setPaymentDetails((prev)=>({...prev,[e.target.name]:e.target.value}));
  }
  const handlePaymentSelect =(e) => {
    setPaymentDetails((prev)=>({...prev,paymentmethod:e.target.options[e.target.selectedIndex].value}))
  }


  const handleDelete = () =>{
    const api = `https://herokumastercms.herokuapp.com/api/v1/invoices/${params.invoiceId}`;
    const token = sessionStorage.getItem("token");
    axios.delete(api,{headers:{"authorization":token}})
    .then(res=>alert("Invoice Deleted successfully"))
    .catch(error=>(
      console.log(error)
    ))

    window.location.reload(); 
  }
  const handleModalSubmit = (e) =>{
    e.preventDefault();
    // console.log(paymentDetails);
    const api = `https://herokumastercms.herokuapp.com/api/v1/invoices/${params.invoiceId}`;
    const token = sessionStorage.getItem("token");
    axios.put(api,{paymentDetails:paymentDetails,status:paymentDetails.pendingamt > 0 && paymentDetails.pendingamt <invoiceData.invoice.nettotal? "Partly Paid" :"Paid"},{headers:{"authorization":token}})
    .then(res=>alert("Payment Details updated successfully"))
    .catch(error=>(
      console.log(error)
    ))
    setIsOpen(!isOpen);
    setRefetch(true);
    
  }
  const [invoiceData,setInvoiceData] = useState({
    invoice:{
      invoiceserial:"",
      issueddate:"",
      duedate:"",
      items:[],
      paymentdetails:{}
    },
    customer:"",
    header:"",
    paymentDetails:{
      dop:"",
    }
  });
  const [isOpen, setIsOpen] = useState(false);
  

  const handleChange = (event) => {
    setTemplate(event.target.value);
  };

  const params = useParams();
  
  useEffect(()=>{
    const api = `https://herokumastercms.herokuapp.com/api/v1/invoices/${params.invoiceId}`;
    const tempApi = "https://herokumastercms.herokuapp.com/api/v1/templates/"
    const token = sessionStorage.getItem("token");
    axios.get(api,{headers:{"authorization":token}})
    .then(res=>setInvoiceData(res.data))
    .catch(error =>
      console.log(error)
    )

    axios.get(tempApi,{headers:{"authorization":token}})
    .then(res=>setAllTemplate(res.data));
  },[invoiceData,refetch]);

  
 

 
  return (
    <>
    <Layout>
        <div className="invoice__detail__container">
        {
          isOpen ? (<div className="item__box">
          <form className="modal__form" onSubmit={handleModalSubmit}>
              <div className="modal__title">
                  <h4>Add Payment</h4>
                  <button className="modal__close" onClick={()=>setIsOpen(!isOpen)} >Close</button>
              </div>
              <div className="modal__input__box">
                  <label htmlFor="">Date of Payment</label>
                  <input className="modal__input" type="date" placeholder="Date of payment" name='dop' required={true} onChange={handleModalInputChange}/>
              </div>
              <div className="modal__input__box">
                  <label htmlFor="">Payable Amount</label>
                  <input className="modal__input" type="Number" value={ invoiceData.invoice.paymentdetails.pendingamt >0 ? invoiceData.invoice.paymentdetails.pendingamt : invoiceData.invoice.nettotal} disabled={true} onChange={handleModalInputChange}/>
              </div>
              <div className="modal__input__box">
                  <label htmlFor="">Paid Amount</label>
                  <input className="modal__input" type="Number" placeholder="Paid Amount" name='paidamt' required={true} onChange={handleModalInputChange}/>
              </div>
              <div className="modal__input__box">
                  <label htmlFor="">Payment Discount</label>
                  <input className="modal__input" type="Number" placeholder="Payment Amount" name='paymentdiscount'  onChange={handleModalInputChange} />
              </div>
              <div className="modal__input__box">
                  <label htmlFor="">Pending Amount</label>
                  <input className="modal__input" type="Number" placeholder="Pending Amount" value={Math.floor(invoiceData?.invoice.nettotal-paymentDetails.paidamt-paymentDetails.paymentdiscount)} name='pendingamt' required={true} disabled={true} onChange={handleModalInputChange}/>
              </div>
              
              
              <div className="modal__input__box">
                  <label htmlFor="">Payment Method</label>
                  <select className='modal__select' onChange={handlePaymentSelect}>
                    <option  value="upi">UPI</option>
                    <option value="cheque">Cheque</option>
                    <option selected value="cash">Cash</option>
                    <option value="neft">NEFT</option>
                    <option value="rtgs">RTGS</option>
                    
                  </select>
                  {/* <input className="modal__input" type="Number" placeholder="Qty" name='qty' required={true} /> */}
              </div>
              
              <div className="modal__input__box">
                  <label htmlFor="">Transaction ID</label>
                  <input className="modal__input" type="text" placeholder="Transaction ID" name='trax'onChange={handleModalInputChange} />
              </div>
              <div className="modal__input__box">
                  <label htmlFor="">Transaction Fees</label>
                  <input className="modal__input" type="Number" placeholder="Transaction fees" name='traxfees'onChange={handleModalInputChange} />
              </div>
              <div className="modal__input__box">
                  <label htmlFor="">Bank Name</label>
                  <input className="modal__input" type="text" placeholder="Bank Name" name='bankname'onChange={handleModalInputChange} />
              </div> 
              <div className="modal__input__box">
                  <label htmlFor="">Naration</label>
                  <input className="modal__input" type="text" placeholder="Naration" name='naration' onChange={handleModalInputChange}/>
              </div>     
              <button className="modal__save"  >Save</button>
          </form>  
          </div> ) :""
        }
          <div className="back__buton">
            <button onClick={()=>navigate("/invoices")}><KeyboardBackspaceOutlined/>Invoices</button>
          </div>
          <div className="title">
            <div>
              <h2>{invoiceData?.invoice.invoiceserial}</h2>
            </div>
            <button className='back_button'><Preview/>Perview</button>
          </div>
          <div className="title">
           <p style={{color:"green"}}>{invoiceData?.invoice.status}</p>
          </div>

          <div className="detail__box">
            <div className="left__box">
                <div className="card">
                  <div className="card__heder">
                    <p>Invoice Details</p>
                  </div>
                  <div className="card__body">
                    <div className="card__body__item">
                      <label className='item__heading' >Customer Name</label>
                      <label className='item__value' >{invoiceData?.customer}</label>
                    </div>
                    <div className="card__body__item">
                      <label className='item__heading' >Invoice No</label>
                      <label className='item__value' >{invoiceData?.invoice.invoiceserial}</label>
                    </div>
                    <div className="card__body__item">
                      <label className='item__heading' >Issued Date</label>
                      <label className='item__value' >{invoiceData?.invoice.issueddate.slice(0,10)}</label>
                    </div>
                    <div className="card__body__item">
                      <label className='item__heading' >Due Date</label>
                      <label className='item__value' >{invoiceData?.invoice.duedate.slice(0,10)}</label>
                    </div>
                    
                    <div className="card__body__item">
                      <label className='item__heading' >Net Payable</label>
                      <label className='item__value' >Rs.{invoiceData?.invoice.nettotal}</label>
                    </div>
                    <div className="card__body__item">
                      <label className='item__heading' >Sent From</label>
                      <label className='item__value' >{invoiceData?.header}</label>
                    </div>
                    <div className="card__body__item">
                      <label className='item__heading' >Notes</label>
                      <label className='item__value' >{invoiceData?.notes}</label>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card__heder">
                    <p>Payment Details</p>
                  </div>
                  
                  <div className="card__body">
                    <div className="card__body__item">
                      <label className='item__heading' >Date of Payment</label>
                      <label className='item__value' >{invoiceData.invoice.paymentdetails.dop ? invoiceData.invoice.paymentdetails.dop :"Not Available" }</label>
                    </div>
                    <div className="card__body__item">
                      <label className='item__heading' >Amount</label>
                      <label className='item__value' >{invoiceData.invoice.paymentdetails.paidamt ? invoiceData.invoice.paymentdetails.paidamt : 0 }</label>
                    </div>
                    <div className="card__body__item">
                      <label className='item__heading' >Payment Method</label>
                      <label className='item__value' >{invoiceData.invoice.paymentdetails.paymentmethod ? invoiceData.invoice.paymentdetails.paymentmethod :"Not Available" }</label>
                    </div>
                    <div className="card__body__item">
                      <label className='item__heading' >Transaction Id</label>
                      <label className='item__value' >{invoiceData.invoice.paymentdetails.trax ? invoiceData.invoice.paymentdetails.trax : "Not Available" }</label>
                    </div>
                    <div className="card__body__item">
                      <label className='item__heading' >Status</label>
                      <label className='item__value' >{invoiceData.invoice.status }</label>
                    </div>
                    <div className="card__body__item">
                      <label className='item__heading' >Transaction Fess</label>
                      <label className='item__value' >{invoiceData.invoice.paymentdetails.traxfees ? invoiceData.invoice.paymentdetails.traxfees : 0 }</label>
                    </div>
                    <div className="card__body__item">
                      <label className='item__heading' >Bank Name</label>
                      <label className='item__value' >{invoiceData.invoice.paymentdetails.bankname ? invoiceData.invoice.paymentdetails.bankname :"Not Available" }</label>
                    </div>
                    <div className="card__body__item">
                      <label className='item__heading' >Naration</label>
                      <label className='item__value' >{invoiceData.invoice.paymentdetails.naration ? invoiceData.invoice.paymentdetails.naration: "Not Available" }</label>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card__heder">
                    <p>Item Details</p>
                  </div>
                  <div className="card__body">
                  <TableContainer component={Paper} className='table'>
                    <Table >
                      <TableHead style={{backgroundColor:"rgba(0, 128, 128, 0.05)"}}>
                        <TableRow>
                          
                          
                          <TableCell className='tableCell'>Product</TableCell>
                          <TableCell className='tableCell'>Price</TableCell>
                          <TableCell className='tableCell'>Qty</TableCell>
                          <TableCell className='tableCell'>Total Amount</TableCell>
                          <TableCell className='tableCell'>Total Tax</TableCell>
                          <TableCell className='tableCell'>Total Discount</TableCell>
                          <TableCell className='tableCell'>Net Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoiceData?.invoice.items.map((row) => (
                          <TableRow
                            key={row._id}>
                            
                            
                            <TableCell className='tableCell'>{row.name}</TableCell>
                            <TableCell className='tableCell'>{row.price}</TableCell>
                            <TableCell className='tableCell'>{row.qty}</TableCell>
                            <TableCell className='tableCell'>{row.qty*row.price}</TableCell>
                            <TableCell className='tableCell'>{((row.qty*row.price)*((100-row.discountrate)/100))*row.taxrate/100}</TableCell>
                            <TableCell className='tableCell'>{(row.qty*row.price)*row.discountrate/100}</TableCell>
                            <TableCell className='tableCell'>{(row.qty*row.price)+(((row.qty*row.price)*((100-row.discountrate)/100))*row.taxrate/100)-((row.qty*row.price)*row.discountrate/100)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                    
                  </div>
                </div>  
            </div>
            <div className="right__box">
              <div className="card">
                  <div className="card__heder">
                    <p>Send Notifications</p>
                  </div>
                  <div className="card__body">
                    <Box  style={{padding:"15px",width:"100%"}}>
                      <FormControl fullWidth>
                        <InputLabel id="template">Template</InputLabel>
                        <Select
                          labelId="template"
                          id="templateSelect"
                          value={template}
                          label="Template"
                          onChange={handleChange}
                        >
                          {
                            allTemplate.map((item)=>{
                              return (
                                <MenuItem value={item._id}>{item.name}</MenuItem>
                              )
                            })
                          }
                          
                          
                        </Select>
                      </FormControl>
                    </Box>
                    <div className="action__buttons">
                      <button className='comservice__action__btn'>
                        <EmailOutlined/>
                        Send Email
                      </button>
                      <button className='comservice__action__btn'>
                        <WhatsappOutlined/>
                        Send Whatsapp
                      </button>
                      <button className='comservice__action__btn'>
                        <MessageOutlined/>
                        Send Message
                      </button>
                      <button className='comservice__action__btn'>
                        <CallMadeOutlined/>
                        Make a Call
                      </button>
                    
                    </div>
                    <div className="card__footer">
                      <ul>
                        <li><Preview style={{marginRight:"15px"}}/>Preview</li>
                        <li onClick={()=>setIsOpen(!isOpen)}><PaymentOutlined style={{marginRight:"15px"}}/>Mark as Paid</li>
                        <li><DownloadOutlined style={{marginRight:"15px"}}/>Download PDF</li>
                        <li onClick={handleDelete}><DeleteOutlined style={{marginRight:"15px"}}/>Delete</li>
                        <li><EditOutlined style={{marginRight:"15px"}}/>Edit</li>

                      </ul>
                    </div>   
                  </div>
                  
              </div>
              <div className="card">
                  <div className="card__heder">
                    <p>History</p>
                  </div>
                  <div className="card__body">
                      <ul>
                        <li className='history__log'>Payment recieved and transaction id is #1124kflsjdfkl999 <br/> <span>5 minutes ago</span></li>
                        <li className='history__log'>Whatsapp notification sent with attachment <br/> <span>13 minutes ago</span></li>
                        <li className='history__log'>Email sent with attachment <br/> <span>14 minutes ago</span></li>
                        <li className='history__log'>Invoice created and invoice id is #1124567 <br/> <span>15 minutes ago</span></li>
                      </ul>   
                  </div>
                  
              </div>
            </div>
          </div>
          
        </div>
        
    </Layout>
    </>
  )
}

export default InvoiceDetails