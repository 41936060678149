import React from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

  
  export default function BasicTabs({categories,handletab}) {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      
      setValue(newValue);
      handletab(event.target.innerText.toLowerCase());
    };
  
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={(event,newValue)=>handleChange(event,newValue)} >
            {
                categories.map((category,i)=>{
                    return (
                        <Tab label={category}  key={i}/>
                    )
                })
            }
            
            
          </Tabs>
        </Box>
        <input className='search__input' type="text" placeholder='Search' style={{border:"1px solid #EAECF0", padding:"10px",marginBottom:"20px", fontSize:"12px", marginTop:"10px", outline:"none",borderRadius:"3px",width:"40%"}}/>

        
      </Box>
    );
  }

