import React from 'react'
import "../import/import.scss"
import Navbar from "../../components/Navbar/Navabar"
import Sidebar from "../../components/Sidebar/Sidebar"
import Import from '../../components/ImportCustomer/import'
import { useLocation } from 'react-router-dom'

const ImportFile = ({title}) => {

  const history = useLocation();

  
  return (
    <div className="import">
      <Sidebar/>
      <div className='import__container'>
        <Navbar/>
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <Import pathname={history.pathname} />
          
        </div>
      </div>
    </div>
    
  )
}

export default ImportFile